

		.dropdown-menu	{
			width: 500px;
			position: absolute;
		}

		ul {
   			list-style-type: none;
   			padding: 0;
   		}

   		.notification-item {
   			height: 50px;
   			border-bottom: 1px solid #A5A4BF;
   		}

   		.dropper {
   			position: relative;
   			right: 75px;
   		}

   		.pic-dropper {
   			position: absolute;
   			right: 15px;
   		}

   		.dropdown-menu {
   			margin-top: 40px;
   		}

		.profpic {
		  width: 35px;
		  margin-right: 10px;
		  margin-top: 0px;
		  padding-top: 0px;
		  clip-path: circle(45%);
		}

		.main-icon {
		 font-size: 5rem;
		 margin-left: -18px;
		 }

		 .icon-left-open {
		  padding: 0px;
		  margin: 0px;
		 }

		/* .navbar {
		  background-color: #35344E;
		  border-radius: 0px;
		} */

		/* .container-fluid-noti {
		  padding: 0px;
		} */

		.icon-bell-alt {
		  color: white;
		  font-size: 2rem;
		  line-height: 3.5rem;
		}

		.nav-search {
		  margin-top: 15px;
		  margin-bottom: 0px;
		  padding-bottom: 0px;
		}

		.navbar-toggler {
		  background-color: rgba(255 255 255 0);
		}

		.navbar-right {
		  margin-right: 0px;
		}

		.dark {
		  color: black;
		}

		.navbar-nav {
		  flex-direction: row;
		}

		.notification-time {
		  color: grey;
		}

		.profpic-notifications {
		  width: 25%;
		}

		.right-align {
		  float: right !important;
		  align-items: flex-end;
		}

		.popover {
		  max-width: 30vw;
		  width: auto;
		  padding: 5px;
		  box-shadow: 0px 3px 6px rgba(0, 0, 0, 16%);
		  border: none;
		}

		/* .navbar {
		  height: 55px;
		} */

		.main-ul {
		  height: 55px;
		}

		/* .nav-item {
		  margin-left: 0px;
		  margin-top: 0px;
		  padding-top: 5px;
		} */

		/*Nav responsiveness*/

		@media (min-width: 768px) {
		  .mobilenav {
		    display: none;
		  }
		}

		@media (max-width: 768px) {
		  .mainnav {
		    display: none;
		  }
		  #sidebar {
		    display: none;
		  }
		}
li .notification-box:hover{
  background-color: #686a6b96;
}
.nav-pills .nav-link.active, .nav-pills .show > .nav-link{
  background-color: #17A2B8;
}
.dropdown-menu{
  top: 9px;
  right: 0px;
  left: unset;
  width: 460px;
  box-shadow: 0px 5px 7px -1px #c1c1c1;
  padding-bottom: 0px;
  padding: 0px;
}
.dropdown-menu:before{
  content: "";
  position: absolute;
  top: -20px;
  right: 12px;
  border:10px solid #343A40;
  border-color: transparent transparent #343A40 transparent;
}
.head-notify{
  padding:5px 15px;
  border-radius: 3px 3px 0px 0px;
  margin:0px !important;
}
.drop-footer{
  padding:5px 15px;
  border-radius: 0px 0px 3px 3px;
}
.notification-box{
  padding-left: 20px;
  font-size: 13px;
}
.bg-gray{
  background-color: #eee;
}
.bg-dark {
  background-color: #1b2034  !important;
}
.text-light {
  color: #f8f9fa !important;
}
.text-warning{
  margin-left: 24rem !important;
}
.row-padding{
	padding-left: 25px;
    font-size: 13px;
}
@media (max-width: 640px) {
    .dropdown-menu{
      top: 50px;
      left: -16px;
      width: 290px;
    }
    .nav{
      display: block;
    }
    .nav .nav-item,.nav .nav-item a{
      padding-left: 0px;
    }
    .message{
      font-size: 13px;
    }
}